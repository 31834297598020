<template>
  <div class="container">
    <div class="lang">
      <el-select v-model="value" placeholder="กรุณาเลือก" @change="clickChange">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
    <h2>สัญญาอนุญาตให้ใช้สิทธิซอฟต์แวร์และบริการ UniLive </h2>
    <p>
      บทนำ <br/>
      สัญญาอนุญาตให้ใช้สิทธิและบริการซอฟต์แวร์ UniLive (ต่อไปนี้จะเรียกว่า "ข้อตกลงนี้") คือ PIX TECHNOLOGY PTE.
      ข้อตกลงระหว่างซอฟต์แวร์ UniLive ภายใต้บริษัท LTD (ต่อไปนี้จะเรียกว่า "UniLive" หรือ "ของเรา") และคุณ
      (ต่อไปนี้จะเรียกว่า "ผู้ใช้")
      เกี่ยวกับการดาวน์โหลดติดตั้งลงชื่อเข้าใช้การใช้ซอฟต์แวร์และการใช้บริการที่เกี่ยวข้องกับ UniLive
      โปรดอ่านเนื้อหาของข้อตกลงนี้อย่างละเอียด หากท่านมีข้อสงสัยเกี่ยวกับเนื้อหาของข้อตกลงนี้ หรือข้อมูลคำใบ้ของหน้าเว็บ
      กรุณาอย่าดำเนินการในขั้นตอนต่อไป คุณสามารถปรึกษาผ่านฝ่ายบริการลูกค้าอย่างเป็นทางการของแพลตฟอร์ม UniLive
      เพื่อให้เราสามารถอธิบายและอธิบายให้คุณได้
      คุณยืนยันว่าคุณได้ยอมรับข้อตกลงนี้โดยการคลิกที่หน้าเว็บหรือเริ่มใช้บริการที่เกี่ยวข้องกับซอฟต์แวร์ UniLive โดยตรง
      <br/>
    </p>
    <p>
      หากคุณเป็นผู้เยาว์ที่อายุต่ำกว่า 18
      ปีหรือไม่มีพฤติกรรมทางแพ่งอย่างเต็มที่เนื่องจากสติปัญญาและสุขภาพจิตโปรดอ่านข้อตกลงนี้และข้อตกลงที่เกี่ยวข้องอื่น ๆ
      พร้อมคำแนะนำและมาพร้อมกับผู้ปกครองและให้ความสนใจเป็นพิเศษกับข้อกำหนดที่เกี่ยวข้องกับการใช้งานของผู้เยาว์และการใช้ซอฟต์แวร์
      UniLive และบริการที่เกี่ยวข้องหลังจากได้รับความยินยอมจากผู้ปกครอง
      ข้อควรระวังเป็นพิเศษคือขอให้คุณอย่าใช้บริการที่เกี่ยวข้องกับการเติมเงินที่นำเสนอโดย UniLive
      โดยไม่ได้รับความยินยอมจากผู้ปกครองและขอให้ผู้ปกครองปฏิบัติหน้าที่ผู้ปกครองอย่างจริงจัง
    </p>
    <h3>ข้อตกลงนี้จะช่วยให้คุณเข้าใจเนื้อหาต่อไปนี้</h3>
    <p>I. คำนิยาม </p>
    <p>ii. ขอบเขตของการใช้และการแก้ไขข้อตกลง</p>
    <p>III. ข้อกำหนดการใช้สิทธิ์การใช้งานซอฟต์แวร์ UniLive </p>
    <p>IV. การลงทะเบียน การใช้ และการออกจากบัญชี</p>
    <p>v. การคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ </p>
    <p>VI. ข้อกำหนดการใช้งานสำหรับผู้เยาว์</p>
    <p>VII ข้อกำหนดเกี่ยวกับพฤติกรรมของผู้ใช้</p>
    <p>VIII ข้อกำหนดการใช้งานข้อมูล </p>
    <p>9. ภาระภาษีตามกฎหมาย</p>
    <p>สิบ, ทรัพย์สินทางปัญญา</p>
    <p>สิบเอ็ด, โปรโมชั่น</p>
    <p>สิบสอง. ข้อตกลงพิเศษเกี่ยวกับบริการเดียว</p>
    <p>สิบสาม การเปลี่ยนแปลง การหยุดชะงัก และการยกเลิกบริการ</p>
    <p>สิบสี่, การปฏิเสธความรับผิดชอบ</p>
    <p>สิบห้า, การประมวลผลการละเมิด</p>
    <p>สิบหก, อื่น ๆ</p>
    <h3> I. คำนิยาม </h3>
    <p>
      1. แพลตฟอร์ม UniLive หมายถึง UniLiveAPP และเว็บไซต์อื่น ๆ
      เว็บเพจไคลเอ็นต์แอพเพล็ตและผู้ให้บริการใหม่ที่เกิดขึ้นกับการพัฒนาเทคโนโลยีในภายหลัง
    </p>
    <p>
      2. บริการ/บริการ หมายถึง การให้บริการที่เกี่ยวข้องแก่ผู้ใช้ผ่านแพลตฟอร์ม UniLive (รวมถึงแต่ไม่จำกัดเพียง
      การให้บริการพื้นที่เก็บข้อมูล และให้บริการเสียงและวิดีโอทางเว็บ เว็บไลฟ์ การค้นหา ฯลฯ รวมถึงบริการอื่นๆ
      ที่เกี่ยวข้องแก่ผู้ใช้)
    </p>
    <p>
      3. ผู้ใช้ UniLive / ผู้ใช้รวมถึงผู้ใช้ที่ลงทะเบียนและผู้ที่ไม่ได้ลงทะเบียน
      ผู้ใช้ที่ลงทะเบียนหมายถึงผู้ใช้ที่สร้างบัญชี UniLive เพื่อใช้คุณลักษณะและบริการบางอย่างของแพลตฟอร์ม UniLive
      ผู้ใช้ที่ไม่ได้ลงทะเบียนผลิตภัณฑ์และ /
      หรือบริการของเราซึ่งกลายเป็นผู้ใช้ที่ไม่ได้ลงทะเบียนของเราตั้งแต่เริ่มใช้ผลิตภัณฑ์และ /
      หรือบริการของเราจะต้องปฏิบัติตามข้อกำหนดอื่น ๆ
      ทั้งหมดในข้อตกลงนี้ยกเว้นข้อตกลงเฉพาะสำหรับผู้ใช้ที่ลงทะเบียนในระหว่างการใช้งาน
    </p>
    <p>
      4. UniLive Account / Account หมายถึงหมายเลขบัญชีที่คุณลงทะเบียนและใช้กับแพลตฟอร์ม UniLive
      หลังจากที่คุณลงทะเบียนหมายเลขบัญชี UniLive Platform แล้วคุณสามารถทำหน้าที่อย่างใดอย่างหนึ่งหรือมากกว่าซึ่งรวมถึง
      แต่ไม่ จำกัด เพียงการถ่ายภาพสด / วิดีโอสั้น ๆ การโพสต์การดูความคิดเห็นการแบ่งปันการค้นหาและอื่น ๆ ผ่านแพลตฟอร์ม
      UniLive
    </p>
    <p>
      5. กฎของแพลตฟอร์ม UniLive รวมถึงเอกสารกฎข้อบังคับต่าง ๆ เช่น ข้อกำหนดในการให้บริการ กฎ ข้อกำหนด การตีความกฎ
      รายละเอียดการดำเนินงาน การแจ้งเตือน การประกาศ ฯลฯ ซึ่ง UniLive ได้เผยแพร่หรืออาจเผยแพร่ แก้ไขในอนาคต
      โดยขึ้นอยู่กับการแสดงหน้าเว็บที่เกี่ยวข้อง
      สิ่งที่ต้องเตือนเป็นพิเศษคือคุณให้ความสำคัญกับข้อกำหนดในการให้บริการหรือกฎที่เกี่ยวข้องเมื่อใช้ผลิตภัณฑ์และ /
      หรือบริการบางอย่างในแพลตฟอร์ม UniLive
      เมื่อคุณยอมรับข้อตกลงนี้หรือใช้บริการดังกล่าวจริงจะถือว่าคุณยอมรับข้อกำหนดดังกล่าว
    </p>
    <h3> ครั้งที่สอง ขอบเขตของการใช้และการแก้ไขข้อตกลง</h3>
    <p>
      1. ขอบเขตหลักของข้อตกลง โปรดทราบว่าแพลตฟอร์ม UniLive
      เป็นแพลตฟอร์มบริการแบบครบวงจรซึ่งคุณสามารถกำหนดผู้ดำเนินการแพลตฟอร์ม UniLive
      ที่ทำสัญญากับคุณได้ตามข้อตกลงที่ลงนามโดยบริการที่เกี่ยวข้องที่ใช้หรือข้อมูลที่ประกาศโดยบริการที่เกี่ยวข้อง
      ภายใต้ข้อตกลงนี้ผู้ประกอบการแพลตฟอร์ม UniLive อาจมีการเปลี่ยนแปลงตามการปรับเปลี่ยนทางธุรกิจของแพลตฟอร์ม UniLive
      และการเปลี่ยนแปลงผู้ประกอบการแพลตฟอร์ม UniLive จะปฏิบัติตามข้อตกลงนี้และให้บริการแก่คุณ ผู้ประกอบการแพลตฟอร์ม
      UniLive ยังมีความเป็นไปได้ที่จะเพิ่มใหม่เนื่องจากแพลตฟอร์ม UniLive
      ให้บริการใหม่เช่นคุณใช้บริการที่เพิ่มใหม่ถือว่าคุณยินยอมให้ผู้ประกอบการแพลตฟอร์ม UniLive
      ที่สอดคล้องกับบริการที่เพิ่มใหม่เพื่อดำเนินการตามข้อตกลงนี้กับคุณ
      เมื่อคุณมีข้อพิพาทเกี่ยวกับการใช้บริการบางอย่างภายในแพลตฟอร์ม UniLive คู่พิพาทจะเป็นผู้ดำเนินการแพลตฟอร์ม UniLive
      ที่สอดคล้องกับบริการที่คุณใช้เฉพาะ
    </p>
    <p>
      2. ขอบเขตของข้อตกลง,
      ข้อตกลงนี้รวมถึงกฎของแพลตฟอร์ม UniLive ซึ่งเป็นส่วนหนึ่งของข้อตกลงนี้และมีผลทางกฎหมายเทียบเท่ากับข้อตกลงนี้
      นอกจากนี้เมื่อคุณใช้ผลิตภัณฑ์และ /
      หรือบริการที่นำเสนอโดยบุคคลที่สามบนแพลตฟอร์มของเรานอกเหนือไปจากการปฏิบัติตามข้อตกลงในข้อตกลงนี้จะต้องปฏิบัติตามข้อกำหนดในการให้บริการของบุคคลที่สาม
    </p>
    <p>
      3. การแก้ไขข้อตกลง
      UniLive
      อาจแก้ไขข้อตกลงนี้ในเวลาที่เหมาะสมตามความจำเป็นในการเปลี่ยนแปลงกฎหมายและข้อบังคับนโยบายของรัฐเงื่อนไขทางเทคนิคฟังก์ชั่นของผลิตภัณฑ์
      ฯลฯ และ UniLive จะเผยแพร่ข้อตกลงที่แก้ไขแล้ว เมื่อเนื้อหาดังกล่าวได้รับการเผยแพร่อย่างเป็นทางการแล้ว UniLive
      จะถูกส่งไปยังผู้ใช้ในลักษณะที่เหมาะสม (พร้อมท์หน้าต่างบานเกล็ดการประกาศเว็บไซต์การแจ้งเตือนระบบ ฯลฯ
      ตามความเป็นจริง)
      หากคุณมีคำถามเกี่ยวกับเนื้อหาของข้อตกลงที่แก้ไขหรือข้อมูลคำแนะนำของหน้าเว็บคุณสามารถปรึกษาผ่านฝ่ายบริการลูกค้าอย่างเป็นทางการของแพลตฟอร์ม
      UniLive เพื่อให้เราสามารถอธิบายและอธิบายให้คุณได้ เมื่อท่านยืนยันโดยการคลิกหน้าเว็บหรือวิธีการอื่น ๆ
      (เช่นการใช้งานต่อไป) แสดงว่าท่านได้ยอมรับข้อตกลงที่แก้ไขแล้ว
    </p>
    <h3>III. ข้อกำหนดการใช้สิทธิ์การใช้งานซอฟต์แวร์ UniLive </h3>
    <p>1. ขอบเขตของการอนุญาตให้ใช้ซอฟต์แวร์</p>
    <p>
      1.1
      คุณอาจจำเป็นต้องดาวน์โหลดซอฟต์แวร์ UniLive เพื่อใช้บริการและสำหรับซอฟต์แวร์ UniLive และเทคโนโลยี (ถ้ามี) UniLive
      ให้สิทธิ์เฉพาะบุคคลไม่สามารถเพิกถอนได้ไม่สามารถถ่ายโอนได้และไม่ผูกขาด คุณสามารถดาวน์โหลด ติดตั้ง ใช้
      เรียกใช้ซอฟต์แวร์ UniLive เพื่อวัตถุประสงค์ในการใช้บริการเท่านั้น
    </p>
    <p>
      1.2
      สิทธิอื่น ๆ ทั้งหมดที่ไม่ได้รับอนุญาตอย่างชัดแจ้งในข้อนี้และข้อกำหนดอื่น ๆ ของข้อตกลงนี้ยังคงสงวนไว้โดย UniLive
      และคุณต้องได้รับอนุญาตเป็นลายลักษณ์อักษรจาก UniLive ในการใช้สิทธิที่สงวนไว้เหล่านี้ UniLive
      ไม่ถือเป็นการสละสิทธิ์ในการสงวนสิทธิ์ในกรณีที่ไม่มีการใช้สิทธิ
    </p>
    <p>
      2. การได้มาซึ่งซอฟต์แวร์
    </p>
    <p>
      2.1 การที่ท่านใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้อง ท่านสามารถรับแอพพลิเคชั่น UniLive
      ได้โดยการติดตั้งไว้ล่วงหน้า การดาวน์โหลดจากบุคคลที่สามที่ได้รับอนุญาตจาก UniLive เช่น App Store เป็นต้น
    </p>
    <p>
      2.2 หากคุณได้รับซอฟต์แวร์ UniLive จากบุคคลที่สามที่ไม่ใช่ UniLive หรือไม่ได้รับอนุญาตจาก UniLive UniLive
      ไม่สามารถรับประกันได้ว่าซอฟต์แวร์จะสามารถใช้งานได้อย่างถูกต้องและจะไม่รับผิดชอบต่อความเสียหายที่เกิดขึ้นกับคุณ
    </p>
    <p>
      3. การติดตั้งและถอนการติดตั้งซอฟต์แวร์
    </p>
    <p>
      3.1 UniLive อาจพัฒนาซอฟต์แวร์เวอร์ชันต่างๆสำหรับเทอร์มินัลที่แตกต่างกันระบบ ฯลฯ
      โปรดเลือกดาวน์โหลดเวอร์ชันที่เหมาะสมสำหรับการติดตั้งตามสถานการณ์จริง
    </p>
    <p>
      3.2 หลังจากดาวน์โหลดโปรแกรมติดตั้งแล้ว โปรดทำตามขั้นตอนที่โปรแกรมได้แจ้งในการติดตั้งอย่างถูกต้อง
    </p>
    <p>
      3.3 หากคุณไม่จำเป็นต้องใช้ซอฟต์แวร์อีกต่อไปหรือต้องการติดตั้งซอฟต์แวร์เวอร์ชันใหม่สามารถถอนการติดตั้งได้ด้วยตัวเอง
      หากคุณต้องการช่วย UniLive
      ปรับปรุงการบริการผลิตภัณฑ์เราหวังเป็นอย่างยิ่งว่าคุณจะสามารถแจ้งเหตุผลในการถอนการติดตั้งได้
    </p>
    <p>
      4. การปรับปรุงซอฟต์แวร์
    </p>
    <p>
      4.1 เพื่อปรับปรุงประสบการณ์ของผู้ใช้และปรับปรุงเนื้อหาการให้บริการ UniLive จะพยายามพัฒนาบริการใหม่ ๆ
      อย่างต่อเนื่องและให้การอัพเดตซอฟต์แวร์แก่คุณเป็นครั้งคราว
      (การอัปเดตเหล่านี้อาจอยู่ในรูปแบบของการเปลี่ยนซอฟต์แวร์การปรับเปลี่ยนการปรับปรุงคุณสมบัติการอัพเกรดเวอร์ชัน ฯลฯ )
    </p>
    <p>
      4.2 เพื่อเป็นการรับประกันความปลอดภัยและการทำงานของซอฟต์แวร์และบริการ UniLive
      ขอสงวนสิทธิ์ในการปรับปรุงซอฟต์แวร์หรือเปลี่ยนแปลงหรือจำกัดการทำงานของซอฟต์แวร์โดยไม่ต้องแจ้งให้ท่านทราบเป็นพิเศษ
    </p>
    <p>
      4.3 ซอฟต์แวร์เวอร์ชันใหม่อาจไม่สามารถใช้งานได้ทั้งหมดหรือบางส่วนของซอฟต์แวร์เวอร์ชันเก่า UniLive
      ไม่รับประกันว่าซอฟต์แวร์รุ่นเก่าจะยังคงมีอยู่และให้บริการลูกค้าที่สอดคล้องกันโปรดดาวน์โหลดเวอร์ชันล่าสุดในเวลาที่เหมาะสม
    </p>
    <h3>IV. การลงทะเบียน การใช้ และการออกจากบัญชี</h3>
    <p>
      1. ลงทะเบียน
      คุณสามารถสร้างบัญชี UniLive เพื่อใช้คุณลักษณะและบริการบางอย่างของแพลตฟอร์มของเรา
      คุณสามารถลงทะเบียน/เข้าสู่ระบบบัญชี UniLive ได้โดยตรงบนแพลตฟอร์มของเรา
    </p>
    <p>
      2. การใช้
    </p>
    <p>
      2.1 เป็นความรับผิดชอบของผู้ใช้ในการรักษาความปลอดภัยของข้อมูลบัญชี UniLive และรหัสผ่านของบัญชีอย่างเหมาะสม
      และผู้ใช้จะต้องรับผิดชอบทางกฎหมายต่อบัญชี UniLive และการกระทำภายใต้รหัสผ่าน
      ผู้ใช้ตกลงที่จะไม่เปิดเผยหมายเลขบัญชีและข้อมูลรหัสผ่านแก่ผู้อื่นในทุกกรณี โปรดทราบว่า UniLive
      จะไม่ขอรหัสผ่านหมายเลขบัญชีจากคุณ กรุณาแจ้ง UniLive ทันทีเมื่อคุณสงสัยว่าบัญชีถูกขโมย ฯลฯ
      เกี่ยวกับความปลอดภัยของบัญชี UniLive
      จะพยายามรักษาความปลอดภัยของบัญชีของคุณที่ฝั่งเซิร์ฟเวอร์ในระดับเทคโนโลยีปัจจุบันและอัปเดตมาตรการทางเทคนิคอย่างต่อเนื่อง
      ความสูญเสียและผลกระทบที่เกิดจากการรั่วไหลโดยสมัครใจของคุณหรือเนื่องจากคุณถูกโจมตีโดยผู้อื่นการฉ้อโกงและอื่น ๆ
      คุณสามารถเรียกคืนจากผู้ละเมิดผ่านช่องทางการบรรเทาทุกข์เช่นการพิจารณาคดีการบริหาร ฯลฯ หากคุณต้องการความช่วยเหลือจาก
      UniLive UniLive จะพยายามอย่างเต็มที่เพื่อช่วยเหลือภายใต้กรอบของกฎหมายในเวลานั้น
    </p>
    <p>
      2.2 กรรมสิทธิ์ของบัญชี UniLive เป็นของ Beijing UniLive Technology Co. , Ltd.
      หลังจากผู้ใช้เสร็จสิ้นขั้นตอนการลงทะเบียนการสมัครบัญชี UniLive ที่ลงทะเบียนจะถูก จำกัด ด้วยตัวคุณเองเท่านั้น
      ในขณะเดียวกันเนื่องจากพฤติกรรมของบัญชีของคุณ (รวมถึงแต่ไม่ จำกัด เพียงการลงนามในข้อตกลงต่าง ๆ
      ออนไลน์การโพสต์ข้อมูลการซื้อสินค้าและบริการและการเปิดเผยข้อมูล ฯลฯ )
      เป็นการกระทำในนามของคุณเองคุณควรรักษาข้อมูลบัญชีและรหัสผ่านของคุณอย่างถูกต้องและรับผิดชอบต่อผลลัพธ์ของการกระทำของบัญชีของคุณและคุณไม่สามารถให้ยืมให้เช่าให้โอนการขายหรืออนุญาตให้ผู้อื่นใช้บัญชี
      UniLive ในรูปแบบใด ๆ โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก UniLive
      ในกรณีที่บัญชีของคุณถูกดำเนินการหรือใช้งานโดยไม่ใช่ของคุณเอง โปรดแจ้ง UniLive
      ทันทีเพื่อหลีกเลี่ยงความสูญเสียและผลกระทบที่เพิ่มขึ้น เช่น UniLive ตัดสินว่าการใช้บัญชี UniLive
      ของคุณอาจเป็นอันตรายต่อความปลอดภัยของบัญชีของคุณและ / หรือความปลอดภัยของข้อมูลซอฟต์แวร์ UniLive
      ขึ้นอยู่กับการรักษาทรัพย์สินของคุณและการพิจารณาความปลอดภัยของข้อมูลคุณยอมรับว่า UniLive
      อาจระงับการให้บริการที่เกี่ยวข้องจนกว่าคุณจะร้องขอการกู้คืนโดยวิธีที่เชื่อถือได้และภัยคุกคามต่อความปลอดภัยของข้อมูลซอฟต์แวร์
      UniLive ของบัญชีนั้นได้รับการกำจัด
    </p>
    <p>
      2.3 ท่านเข้าใจและรับรองว่าข้อมูลของบัญชี UniLive ที่ท่านตั้งไว้จะต้องไม่ละเมิดกฎหมายและระเบียบข้อบังคับของประเทศ
      นโยบาย และกฎระเบียบที่เกี่ยวข้องของบริการที่ UniLive ให้บริการ
      ข้อมูลที่ผิดกฎหมายหรือข้อมูลที่ไม่พึงประสงค์ในการลงทะเบียนเช่นชื่อบัญชี, หมายเลข UniLive, อวตาร,
      การแนะนำส่วนบุคคลและข้อมูลส่วนบุคคลที่คุณได้ตั้งค่า, การลงทะเบียนบัญชี UniLive หรือการตั้งค่าข้อมูลบัญชี UniLive
      จะไม่ปรากฏในชื่อของผู้อื่นโดยไม่ได้รับอนุญาตจากผู้อื่น (รวมถึง แต่ไม่ จำกัด เพียงการแอบอ้างชื่อของผู้อื่น, ชื่อ,
      เครื่องหมายการค้า, หมายเลขคำ, ภาพบุคคล, อวตาร, ฯลฯ
      ที่มีองค์ประกอบที่ไม่ซ้ำกันหรือวิธีการที่เพียงพอที่จะทำให้เกิดความสับสน)
    </p>
    <p>
      2.4
      ตามข้อกำหนดของกฎหมายและข้อบังคับของประเทศที่เกี่ยวข้องและข้อกำหนดของนโยบายการกำกับดูแลเพื่ออำนวยความสะดวกแก่สาธารณชนในการดำเนินการกำกับดูแลและสร้างระเบียบที่ดีของชุมชนเพื่อประโยชน์สาธารณะ
      UniLive จะแสดงข้อมูลเช่นที่อยู่ของ Internet Protocol (IP)
      ของบัญชีที่คุณใช้อยู่ในขอบเขตที่เหมาะสมในสถานที่เช่นหน้าข้อมูลส่วนตัวของบัญชีของคุณ
      สถานที่แสดงที่เฉพาะเจาะจงรูปแบบประเภทของข้อมูลและอื่น ๆ
      การจัดการที่เกี่ยวข้องในการแสดงจะขึ้นอยู่กับบทบัญญัติของกฎหมายและข้อบังคับที่เกี่ยวข้องและการแสดงที่เกิดขึ้นจริง
    </p>
    <p>
      2.5 เพื่อปกป้องความปลอดภัยของบัญชีและป้องกันไม่ให้เกิดเหตุการณ์เช่นบัญชีถูกขโมย
      เราอาจใช้วิธีหนึ่งหรือหลายวิธีในการยืนยันตัวตนของผู้ใช้ที่ลงทะเบียน (เช่น การยืนยันทางไปรษณีย์ ฯลฯ ) เป็นครั้งคราว
      หากผู้ใช้ไม่ผ่านการยืนยันตัวตน เรามีเหตุผลอันสมควรที่จะสงสัยว่าบัญชีนั้นมีความไม่ปลอดภัย เช่น การโจรกรรม
      และอาจตัดสินใจเพียงฝ่ายเดียวว่าจะระงับหรือยุติการให้บริการผลิตภัณฑ์และ/หรือบริการของเราต่อหรือดำเนินมาตรการเพิ่มเติมขึ้นอยู่กับสถานการณ์ที่ร้ายแรง
      คุณยอมรับว่าเราใช้มาตรการดังกล่าวเพื่อปกป้องความปลอดภัยของบัญชีผู้ใช้และเราไม่จำเป็นต้องรับผิดชอบอย่างไม่สมเหตุสมผล
    </p>
    <p>
      2.6 ในกรณีที่ผู้ใช้ไม่ได้เข้าสู่ระบบบัญชีติดต่อกันเป็นเวลาสองเดือนหลังจากลงทะเบียนบัญชี UniLive แล้ว UniLive
      มีสิทธิ์ที่จะแช่แข็ง กู้คืน แทนที่ ออกจากระบบบัญชี ในขณะที่มีสิทธิ์ที่จะใช้มาตรการทำความสะอาด เช่น การลบ
      การลบข้อมูลของบัญชีในฐานข้อมูลซอฟต์แวร์ UniLive (รวมถึงแต่ไม่จำกัดเพียงข้อมูลการลงทะเบียน สินทรัพย์เสมือน ฯลฯ)
      เพื่อไม่ให้เกิดการสิ้นเปลืองทรัพยากรและความเสียหายใด ๆ ที่เกิดขึ้นถือเป็นความรับผิดชอบของผู้ใช้เอง
    </p>
    <p>
      2.7 ในกรณีที่บัญชีของท่านถูกแช่แข็ง ท่านอาจเปิดใช้งานบัญชีของท่านอีกครั้งโดยวิธีการยืนยันอื่นๆ
      ที่มีให้โดยแพลตฟอร์ม UniLive
    </p>
    <p>
      3. ออกจากระบบ
      เรามีฟังก์ชั่นการออกจากระบบบัญชี UniLive
      ซึ่งคุณสามารถออกจากบัญชีของคุณผ่านทางวิธีการออกจากระบบออนไลน์ที่นำเสนอโดยแพลตฟอร์ม UniLive
      ติดต่อฝ่ายบริการลูกค้าของเราหรือผ่านวิธีการอื่นที่เรานำเสนอ
    </p>
    <h3>V. การคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้ </h3>

    <p>
      1.
      เราตระหนักดีถึงความสำคัญของข้อมูลส่วนบุคคลต่อคุณดังนั้นเราจึงให้ความสำคัญกับการปกป้องความเป็นส่วนตัวและข้อมูลส่วนบุคคลของคุณและปฏิบัติต่อข้อมูลส่วนบุคคลของคุณด้วยภาระหน้าที่ที่รอบคอบสูง
      เราจะใช้มาตรการทางเทคนิคที่เกี่ยวข้องและมาตรการรักษาความปลอดภัยอื่น ๆ
      เพื่อปกป้องข้อมูลส่วนบุคคลของคุณในระหว่างการใช้ผลิตภัณฑ์และ/หรือบริการของเรา
    </p>
    <p>
      2.
      ในระหว่างที่คุณลงทะเบียนหมายเลขบัญชีหรือใช้บริการที่เกี่ยวข้องเราจำเป็นต้องให้ความร่วมมือในการให้ข้อมูลที่จำเป็นบางอย่างเช่นกล่องจดหมายของคุณ
      ฯลฯ
      ซึ่งคุณไม่สามารถใช้บริการหรือถูกจำกัดในระหว่างการใช้หากคุณไม่เห็นด้วยกับการอนุมัติข้อมูลส่วนบุคคลที่จำเป็นสำหรับบริการที่เกี่ยวข้อง
    </p>
    <p>
      3.
      เราให้ความสำคัญอย่างยิ่งกับการจัดการข้อมูลส่วนบุคคลของคุณและพยายามอย่างเต็มที่เพื่อปกป้องสิทธิของคุณในการเข้าถึงคัดลอกแก้ไขเพิ่มเติมลบถอนการอนุญาตความยินยอมและการยกเลิกหมายเลขบัญชีการร้องเรียนการแจ้งเบาะแสและอื่น
      ๆ เกี่ยวกับข้อมูลส่วนบุคคลของคุณเพื่อให้คุณสามารถทราบและรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
    </p>
    <p>
      4. เราให้ความสำคัญกับการปกป้องข้อมูลส่วนบุคคลของผู้เยาว์ หากคุณเป็นผู้เยาว์อายุต่ำกว่า 18 ปี
      คุณควรได้รับความยินยอมจากพ่อแม่หรือผู้ปกครองคนอื่นก่อนใช้บริการของ UniLive
    </p>
    <p>
      5. สำหรับวิธีที่ UniLive จะเก็บรวบรวม ใช้ จัดเก็บ และปกป้องข้อมูลส่วนบุคคลของคุณ และสิทธิที่คุณได้รับ
      โปรดเยี่ยมชมนโยบายความเป็นส่วนตัวของ UniLive เพื่อเรียนรู้เพิ่มเติม
    </p>
    <h3>หก. ข้อกำหนดการใช้งานสำหรับผู้เยาว์</h3>
    <p>
      1. หากคุณเป็นผู้เยาว์อายุต่ำกว่า 18 ปีคุณควรอ่านข้อตกลงนี้และใช้ซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องภายใต้การดูแลคำแนะนำและได้รับความยินยอมจากผู้ปกครอง
    </p>
    <p>
      2. UniLive ให้ความสำคัญกับการปกป้องข้อมูลส่วนบุคคลของผู้เยาว์
      ผู้ใช้ที่ยังไม่บรรลุนิติภาวะในการกรอกข้อมูลส่วนบุคคลโปรดเสริมสร้างความตระหนักในการปกป้องส่วนบุคคลและปฏิบัติด้วยความระมัดระวังโปรดใช้ซอฟต์แวร์
      UniLive และบริการที่เกี่ยวข้องอย่างถูกต้องตามคำแนะนำของผู้ปกครอง
    </p>
    <p>
      3.
      การใช้ซอฟต์แวร์และบริการที่เกี่ยวข้องของผู้เยาว์ควรอยู่ภายใต้การดูแลของผู้ปกครองในการเรียนรู้การใช้เครือข่ายอย่างถูกต้องในขอบเขตที่เหมาะสมหลีกเลี่ยงการหลงระเริงในพื้นที่เครือข่ายเสมือนพัฒนานิสัยการท่องอินเทอร์เน็ตที่ดีต้องเก่งในการเรียนรู้ออนไลน์และไม่ท่องผ่านข้อมูลที่ไม่ดี
      มีความซื่อสัตย์สุจริตและเป็นมิตรในการสื่อสาร ไม่ดูหมิ่นหลอกลวงผู้อื่น
      เพื่อเพิ่มความตระหนักในการดูแลตนเองและไม่ออกเดทกับชาวเน็ตตามอําเภอใจ
      เพื่อรักษาความปลอดภัยทางไซเบอร์และไม่ทําลายความสงบเรียบร้อยทางไซเบอร์ ต้องเป็นประโยชน์ต่อสุขภาพกายและสุขภาพใจ
      ไม่ให้หลงระเริงกับกาลอวกาศเสมือนจริง
    </p>
    <p>
      4. ผู้พิทักษ์อาจใช้ฟีเจอร์ต่างๆ เช่น การเติมเงิน การให้รางวัล ฯลฯ เมื่อใช้บริการที่ UniLive ให้บริการ
      คุณเป็นผู้ปกครองขอให้คุณเก็บบัญชีการชำระเงินของคุณไว้เพื่อป้องกันไม่ให้ผู้ปกครองใช้ฟังก์ชั่นการเติมเงินรางวัลผ่านบัญชีการชำระเงินของคุณโดยไม่ได้รับความยินยอมจากคุณ
      UniLive ยินดีร่วมมือกับคุณเพื่อหลีกเลี่ยงพฤติกรรมดังกล่าว
    </p>
    <p>
      5. เพื่อปกป้องสิทธิส่วนบุคคลของผู้เยาว์ได้ดียิ่งขึ้น UniLive
      เตือนให้คุณระมัดระวังในการโพสต์เนื้อหาที่มีภาพของผู้เยาว์และเมื่อมีการโพสต์จะถือว่าคุณได้รับความยินยอมจากผู้มีสิทธิในการแสดงภาพเหมือนของผู้เยาว์และข้อมูลอื่น
      ๆ และอนุญาตให้ UniLive ใช้และจัดการเนื้อหาที่เกี่ยวข้องกับผู้เยาว์ตามข้อตกลงนี้ ในกรณีที่มีสิทธิที่จะแจ้งให้
      UniLive ทราบว่าคุณโพสต์เนื้อหาที่ละเมิดสิทธิของผู้เยาว์หรือบนพื้นฐานของการพิจารณาอื่น ๆ
      เพื่อปกป้องสิทธิของผู้เยาว์ UniLive มีสิทธิที่จะดำเนินการและแจ้งให้คุณทราบเกี่ยวกับเนื้อหาที่คุณโพสต์
    </p>
    <h3>VII ข้อกำหนดเกี่ยวกับพฤติกรรมของผู้ใช้</h3>
    <p>
      1. พฤติกรรมของผู้ใช้กำหนดให้คุณต้องรับผิดชอบต่อการใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องของคุณ
      เว้นแต่จะได้รับอนุญาตตามกฎหมายหรือได้รับอนุญาตเป็นลายลักษณ์อักษรล่วงหน้าจาก UniLive การใช้ซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องจะต้องไม่มีพฤติกรรมดังต่อไปนี้:
    </p>
    <p>
      1.1 ใช้ plug-in, sundown, system หรือเครื่องมือของบุคคลที่สามที่ไม่ได้รับอนุญาตหรือได้รับอนุญาตจาก UniLive
      เพื่อแทรกแซง ทำลาย ดัดแปลง หรือส่งผลกระทบอื่น ๆ ต่อการทำงานปกติของซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้อง
    </p>
    <p>
      1.2 ใช้หรือกระทำการใด ๆ ที่เป็นอันตรายต่อความมั่นคงปลอดภัยของเครือข่ายคอมพิวเตอร์กับซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องซึ่งรวมถึงแต่ไม่ จำกัด เพียง:
    </p>
    <p>
      1.2.1 กิจกรรมที่เป็นอันตรายต่อความมั่นคงทางไซเบอร์ เช่น การบุกรุกเครือข่ายของผู้อื่น
      การรบกวนการทำงานปกติของเครือข่ายของผู้อื่น การขโมยข้อมูลเครือข่าย
      1.2.2 จัดให้มีโปรแกรม เครื่องมือ ที่ใช้เฉพาะในกิจกรรมที่เป็นอันตรายต่อความมั่นคงปลอดภัยทางไซเบอร์ เช่น
      การบุกรุกเครือข่าย การรบกวนการทำงานปกติของเครือข่ายและมาตรการป้องกัน การขโมยข้อมูลเครือข่าย
      1.2.3 ให้ความช่วยเหลือแก่ผู้อื่น เช่น การสนับสนุนด้านเทคนิค การส่งเสริมการโฆษณา การชำระเงิน เป็นต้น
      โดยรู้ว่าผู้อื่นมีส่วนร่วมในกิจกรรมที่เป็นอันตรายต่อความมั่นคงปลอดภัยทางไซเบอร์
      1.2.4 ใช้ข้อมูลที่ไม่ได้รับอนุญาตหรือเข้าสู่เซิร์ฟเวอร์/บัญชีที่ไม่ได้รับอนุญาต
      ๑.๒.๕ นำเข้าสู่ระบบเครือข่ายคอมพิวเตอร์ของประชาชน หรือระบบคอมพิวเตอร์ของผู้อื่น และลบ แก้ไข
      เพิ่มข้อมูลที่เก็บไว้โดยไม่ได้รับอนุญาต
      1.2.6 พยายามสืบค้น สแกน ทดสอบจุดอ่อนของระบบหรือเครือข่าย UniLive
      หรือกระทำการอื่นที่เป็นการทำลายความมั่นคงปลอดภัยของเครือข่ายโดยไม่ได้รับอนุญาต
      1.2.7 พยายามแทรกแซง ทำลายการทำงานปกติของระบบหรือเว็บไซต์ UniLive จงใจเผยแพร่โปรแกรมหรือไวรัสที่เป็นอันตราย
      และการกระทำอื่น ๆ ที่ทำลายรบกวนการให้บริการข้อมูลเครือข่ายปกติ
      1.2.8 การปลอมแปลงชื่อแพ็คเก็ต TCP/IP หรือชื่อบางส่วน
    </p>
    <p>
      1.3 ทำวิศวกรรมย้อนกลับ รวบรวมย้อนกลับ รวบรวม หรือพยายามค้นหาซอร์สโค้ดของซอฟต์แวร์ UniLive
    </p>
    <p>
      1.4 ข้อมูลที่ถูกปล่อยลงในหน่วยความจำปลายทางระหว่างการทำงานของซอฟต์แวร์ UniLive หรือซอฟต์แวร์ UniLive
      ข้อมูลปฏิสัมพันธ์ระหว่างไคลเอ็นต์และฝั่งเซิร์ฟเวอร์ระหว่างการทำงานของซอฟต์แวร์
      และข้อมูลระบบที่จำเป็นสำหรับการทำงานของซอฟต์แวร์ UniLive เพื่อดำเนินการจัดหา คัดลอก แก้ไข เพิ่ม ลบ วางสาย
      หรือสร้างผลงานลอกเลียนแบบในรูปแบบ แต่ไม่จำกัดเพียง การเข้าถึงซอฟต์แวร์ UniLive และระบบที่เกี่ยวข้องโดยใช้ปลั๊กอิน
      แฮงค์เอาท์ การควบคุมกลุ่ม หรือเครื่องมือ/บริการของบุคคลที่สามที่ไม่ได้รับอนุญาตจาก UniLive
    </p>
    <p>
      1.5 เพิ่ม ลบ เปลี่ยนแปลง การทำงาน หรือผลการดำเนินงานของซอฟต์แวร์ UniLive โดยการปรับเปลี่ยน หรือปลอมแปลงคำสั่ง
      ข้อมูล ในการดำเนินงานของซอฟต์แวร์ UniLive หรือการดำเนินงาน หรือการเผยแพร่ซอฟต์แวร์ วิธีการ
      เพื่อวัตถุประสงค์ดังกล่าวข้างต้นต่อสาธารณชน ไม่ว่าการกระทำดังกล่าวจะมีวัตถุประสงค์ทางการค้าหรือไม่ก็ตาม
    </p>
    <p>
      1.6 ลบข้อมูลเกี่ยวกับทรัพย์สินทางปัญญาในซอฟต์แวร์ UniLive (และสำเนา) และเนื้อหาที่เกี่ยวข้อง (เช่นวิดีโอสั้น ๆ )
      หรือแก้ไขลบหลีกเลี่ยงมาตรการทางเทคนิคใด ๆ ที่กำหนดขึ้นเพื่อปกป้องทรัพย์สินทางปัญญา
    </p>
    <p>
      1.7 ใช้ ให้เช่า ให้ยืม ทำซ้ำ ดัดแปลง เชื่อมโยง พิมพ์ซ้ำ รวบรวม เผยแพร่ เผยแพร่ ค้นหาในแนวตั้ง ตั้งเว็บไซต์กระจกเงา
      ฯลฯ สำหรับเนื้อหาที่ UniLive เป็นเจ้าของทรัพย์สินทางปัญญา หรือเนื้อหาที่โพสต์โดยผู้ใช้รายอื่นในแพลตฟอร์ม UniLive
    </p>
    <p>
      1.8 ใช้บัญชี UniLive เพื่อเข้าร่วมในกิจกรรมหรือการทำธุรกรรมที่ผิดกฎหมายหรืออาจผิดกฎหมาย (ตามที่ UniLive ตัดสิน)
      รวมถึงการสอนวิธีการทางอาญา, การขายยาเสพติดที่ผิดกฎหมาย, กิจกรรมการฟอกเงิน, การฉ้อโกง ฯลฯ
    </p>
    <p>
      1.9 การละเมิดกฎหมายและข้อบังคับ ข้อตกลงนี้ กฎของแพลตฟอร์ม UniLive
      และการละเมิดสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของผู้อื่น
      หาก UniLive มีเหตุอันควรเชื่อว่าการกระทำใด ๆ ของคุณละเมิดหรืออาจละเมิดข้อตกลงข้างต้น UniLive
      อาจตัดสินและดำเนินการตามขั้นตอนที่จำเป็นอย่างอิสระและในกรณีฉุกเฉินอาจยุติการให้บริการแก่คุณโดยไม่ต้องแจ้งให้ทราบล่วงหน้าและความรับผิดชอบที่เกี่ยวข้องจะถูกตรวจสอบตามกฎหมาย
    </p>
    <p>
      2. ข้อกำหนดเกี่ยวกับเนื้อหาข้อมูล
    </p>
    <p>
      2.1 เนื้อหาของข้อมูลที่ระบุในข้อนี้หมายถึง เนื้อหาใดๆ ที่ผู้ใช้จัดทำ คัดลอก เผยแพร่
      เผยแพร่ในระหว่างการใช้ซอฟต์แวร์และบริการ รวมถึงแต่ไม่จำกัดเฉพาะข้อมูลที่แสดงในหน้าแรกของแต่ละบุคคล เช่น รูปโปรไฟล์
      ชื่อเล่น การแนะนำตัวบุคคล หรือข้อมูลที่โพสต์ เผยแพร่ เช่น ข้อความ รูปภาพ เสียง การถ่ายทอดสด และเนื้อหาอื่นๆ
      ที่เกิดจากการใช้บัญชี UniLive หรือซอฟต์แวร์และบริการ
    </p>
    <p>
      2.2 ท่านเข้าใจและยอมรับว่า UniLive
      ได้มุ่งมั่นที่จะให้ผู้ใช้มีสภาพแวดล้อมทางเครือข่ายที่ดีต่อสุขภาพและเป็นระเบียบเรียบร้อย และท่านต้องไม่ใช้บัญชี
      UniLive หรือซอฟต์แวร์และบริการนี้ในการผลิต คัดลอก เผยแพร่ เผยแพร่ เนื้อหาที่รบกวนการปฏิบัติงานตามปกติของ UniLive
      รวมถึงการละเมิดสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของผู้ใช้รายอื่นหรือบุคคลที่สาม
    </p>
    <p>2.3กฎพื้นฐานสำหรับการอัปโหลดวิดีโอบน UniLive</p>
		<p>
			แนวทางเนื้อหา (ข้อบังคับของชุมชน)
		</p>
		<p>
			ห้ามเนื้อหารุนแรง: ห้ามอัปโหลดวิดีโอที่แสดงความรุนแรง การทำร้าย การทารุณกรรม หรือการก่อการร้าย รวมถึงฉากที่จำลองความรุนแรง  
		</p>
		<p>
			ห้ามเนื้อหาลามกหรือโจ่งแจ้ง: ห้ามเนื้อหาที่เกี่ยวข้องกับภาพเปลือย การสื่อถึงเรื่องเพศ หรือเนื้อหาสำหรับผู้ใหญ่ในทุกรูปแบบ  
		</p>

		<p>
			ห้ามข้อความแสดงความเกลียดชัง: ห้ามเผยแพร่เนื้อหาที่มีการเลือกปฏิบัติ การโจมตี หรือการปลุกปั่นให้เกิดความเกลียดชังต่อกลุ่มใด ๆ ตามเชื้อชาติ ศาสนา เพศ รสนิยมทางเพศ หรือสัญชาติ  
		</p>
		<p>
			ห้ามการกลั่นแกล้งและคุกคาม: ห้ามอัปโหลดเนื้อหาที่เป็นการดูถูก ข่มขู่ หรือคุกคามผู้อื่น รวมถึงพฤติกรรมที่ไม่เหมาะสมต่อเด็ก  
		</p>
		<p>
			ห้ามพฤติกรรมที่เป็นอันตราย: ห้ามแสดงเนื้อหาที่อาจทำให้เกิดอันตราย เช่น การท้าทายอันตราย การเล่นพิเรนทร์ หรือกิจกรรมที่ผิดกฎหมาย (เช่น การใช้ยาเสพติด อาวุธ)  
		</p>
    <p>
      3. ข้อกำหนดการเข้าร่วมกิจกรรม
    </p>
    <p>
      UniLive หรือบุคคลที่สามบนแพลตฟอร์ม UniLive จะไม่ดำเนินกิจกรรมต่าง ๆ เป็นระยะ ๆ
      ก่อนที่จะเข้าร่วมในกิจกรรมคุณควรไปที่หน้าแคมเปญที่เกี่ยวข้องเพื่ออ่านอย่างรอบคอบเข้าใจกฎของแคมเปญทั้งหมดที่คุณเข้าร่วมในแคมเปญจะถือว่าคุณได้อ่านเข้าใจกฎของแคมเปญนี้อย่างเต็มที่และสมัครใจผูกพันตามกฎของแคมเปญ
      ในระหว่างการอ่านกฎเกณฑ์ของกิจกรรมหากคุณไม่เข้าใจหรือไม่เห็นด้วยกับสิ่งใด ๆ
      ในนั้นโปรดหยุดการมีส่วนร่วมในกิจกรรมที่เกี่ยวข้อง
      โปรดทราบว่าสำหรับผู้ใช้ที่เข้าร่วมกิจกรรมโดยใช้วิธีการที่ไม่เหมาะสม
      (หมายถึงผู้เข้าร่วมกิจกรรมที่ละเมิดข้อตกลงหรือกฎห้ามในกิจกรรม) ระบบควบคุมลมจะคัดกรองและตัดออกโดยอัตโนมัติ
      คุณยอมรับว่า UniLive
      มีสิทธิ์ที่จะตัดสินได้อย่างอิสระว่าการกระทำที่เกี่ยวข้องเป็นการละเมิดหรือไม่ขึ้นอยู่กับความสามารถในการวิเคราะห์ข้อมูลขนาดใหญ่ความสามารถในการระบุทางเทคนิคของแพลตฟอร์มของตนเองและสำหรับผู้ใช้ที่เข้าร่วมกิจกรรมด้วยวิธีการที่ไม่เหมาะสมที่จะตัดสิทธิ์การเข้าร่วมกิจกรรมรางวัลและการชนะของพวกเขา
      ไม่รับรองผลลัพธ์ของการทำกำไรด้วยวิธีการที่ไม่เหมาะสมในขณะที่มีสิทธิ์กู้คืนรางวัลที่ได้รับแล้ว
    </p>
    <p>
      4. รับผิดชอบต่อการกระทำของตนเอง
    </p>
    <p>
      ท่านเข้าใจและยอมรับว่าท่านต้องรับผิดชอบต่อการกระทำภายใต้หมายเลขบัญชีที่ลงทะเบียนไว้ รวมถึงการกระทำใด ๆ
      ที่ท่านโพสต์ ความคิดเห็น และการกดไลค์ การอ้างอิง การค้นหา และผลกระทบใด ๆ ที่เกิดขึ้น
      คุณตัดสินเนื้อหาในบริการนี้ด้วยตัวคุณเองและยอมรับความเสี่ยงทั้งหมดที่เกิดจากการใช้เนื้อหารวมถึงความเสี่ยงที่เกิดจากการพึ่งพาความถูกต้องความถูกต้องความสมบูรณ์หรือการปฏิบัติจริงของเนื้อหา
      UniLive ไม่สามารถและจะไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายที่เกิดจากความเสี่ยงดังกล่าว
    </p>
    <p>
      UniLive เคารพและปกป้องสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมาย เช่น สิทธิในทรัพย์สินทางปัญญา สิทธิในชื่อเสียง
      สิทธิในชื่อ สิทธิความเป็นส่วนตัว ฯลฯ ของท่านและผู้อื่น คุณรับประกันว่าข้อความ รูปภาพ เสียง วิดีโอ การถ่ายทอดสด
      ลิงก์ ฯลฯ ที่อัปโหลดขณะใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องจะไม่ละเมิดสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมาย
      เช่น ทรัพย์สินทางปัญญา ชื่อเสียง ชื่อ ความเป็นส่วนตัว ฯลฯ ของบุคคลที่สาม มิฉะนั้น UniLive
      มีสิทธิ์ที่จะลบเนื้อหาที่ถูกกล่าวหาว่าเป็นการละเมิดเมื่อได้รับแจ้งจากผู้มีสิทธิหรือผู้ที่เกี่ยวข้อง
      ในการตอบสนองต่อการเรียกร้องสิทธิทั้งหมดที่เกิดขึ้นโดยบุคคลที่สามคุณจะต้องจัดการและรับผิดชอบทางกฎหมายทั้งหมดที่อาจเกิดขึ้นด้วยตัวคุณเอง
      ในกรณีที่ UniLive หรือบริษัทในเครือได้รับความเสียหาย (รวมถึง แต่ไม่ จำกัด
      เพียงความเสียหายทางเศรษฐกิจค่าความนิยมเป็นต้น)
      อันเป็นผลมาจากการละเมิดของคุณคุณจะต้องชดใช้ค่าเสียหายทั้งหมดที่เกิดขึ้นกับ UniLive หรือบริษัทในเครือ
    </p>
    <h3>VIII ข้อกำหนดการใช้งานข้อมูล </h3>
    <p>
      1. ผู้ใช้จะต้องไม่ดำเนินการเนื้อหาข้อมูลในซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องโดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก UniLive:
    </p>
    <p>
      1.1 ทำซ้ำ อ่าน ใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องเพื่อวัตถุประสงค์ทางการค้า เช่น การส่งเสริมการขาย
      การเพิ่มปริมาณการอ่าน การดู เป็นต้น
    </p>
    <p>
      1.2 แสดงในช่องทางอื่นนอกเหนือจากหน้าต้นทางของซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องโดยไม่ได้รับอนุญาตหลังจากแก้ไข จัดระเบียบ และจัดเรียงเนื้อหาข้อมูลของซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้อง
    </p>
    <p>
      1.3 ใช้วิธีการระบุตัวตนในรูปแบบใด ๆ ซึ่งรวมถึงแต่ไม่ จำกัด เพียงการระบุรหัสพิเศษและอื่น ๆ
      เพื่อช่วยเหลือบุคคลที่สามในการกระทำที่ไม่เหมาะสมเช่นการเข้าชมการชี้นำการถ่ายโอนการจี้ข้อมูลหรือเนื้อหาของซอฟต์แวร์
      UniLive และบริการที่เกี่ยวข้อง
    </p>
    <p>
      1.4 การกระทำอื่นๆ ที่เป็นการได้มาซึ่งเนื้อหาข้อมูลของซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องโดยมิชอบ
    </p>
    <p>
      2. เมื่อได้รับอนุญาตเป็นลายลักษณ์อักษรจาก UniLive ผู้ใช้ควรปฏิบัติตามข้อกำหนดต่อไปนี้:
    </p>
    <p>
      2.1 ข้อมูลที่เกี่ยวข้อง เช่น Grabby, Statistics, Search Hot Words, Hits, Categories, Search Rate, Click, View ฯลฯ
      จะต้องไม่เผยแพร่ จัดหา หรือเปิดเผยข้อมูลดังกล่าวต่อบุคคลที่สามโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจาก UniLive
    </p>
    <p>
      2.2 ไม่มีการเปลี่ยนแปลงใด ๆ กับหน้าเว็บต้นฉบับของซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องรวมถึงแต่ไม่ จำกัด
      เพียงทางเข้า เช่น ลิงก์หน้าแรก (โปรไฟล์) ลิงก์ระบบโฆษณา ฯลฯ ของซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องหรือการปิดกั้นการแทรกหน้าต่างบานเกล็ด ฯลฯ ในการแสดงหน้าต้นฉบับของซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้อง
    </p>
    <p>
      2.3 มีมาตรการที่ปลอดภัย มีประสิทธิภาพ และเข้มงวดเพื่อป้องกันไม่ให้เนื้อหาข้อมูลของซอฟต์แวร์ UniLive
      และบริการที่เกี่ยวข้องถูกนำมาโดยบุคคลที่สามโดยผิดกฎหมายในรูปแบบใด ๆ ซึ่งรวมถึงแต่ไม่จำกัดเพียง โปรแกรม "แมงมุม"
      (Spider)
    </p>
    <p>
      2.4 ห้ามใช้เนื้อหาข้อมูลที่เกี่ยวข้องเพื่อวัตถุประสงค์นอกขอบเขตการอนุญาตเป็นลายลักษณ์อักษรของ UniLive
      เพื่อการขายและการใช้งานเชิงพาณิชย์ในรูปแบบใด ๆ หรือรั่วไหลจัดหาหรืออนุญาตให้บุคคลที่สามใช้งานเพื่อวัตถุประสงค์ใด ๆ
    </p>
    <p>
      2.5 การกระทำของผู้ใช้ในการแบ่งปัน ส่งต่อ ทำซ้ำเนื้อหาของซอฟต์แวร์และข้อมูลบริการที่เกี่ยวข้องของ UniLive
      ไปยังบุคคลที่สามใด ๆ จะต้องปฏิบัติตามข้อกำหนดและมาตรฐานอื่น ๆ ที่ UniLive กำหนดไว้เพื่อการนี้
    </p>
    <h3>เก้า ภาระภาษีตามกฎหมาย</h3>
    <p>
      รายได้ที่เกี่ยวข้องเช่นรายได้หรือผลตอบแทนที่คุณได้รับบนแพลตฟอร์ม UniLive (ต่อไปนี้จะเรียกว่า
      "รายได้ที่เกี่ยวข้อง") สามารถถอนไปยังบัญชีการชำระเงินของคุณเอง รายได้ที่คุณสร้างขึ้นบนแพลตฟอร์ม UniLive
      จะต้องชำระภาษีที่เกี่ยวข้องตามกฎหมายหรือการยื่นแบบแสดงรายการภาษีตามกฎหมายของประเทศที่เกี่ยวข้องกฎระเบียบด้านการบริหารและเอกสารที่เกี่ยวข้องกับข้อกำหนดด้านภาษี
      คุณเข้าใจและยอมรับว่าแพลตฟอร์ม UniLive จำเป็นต้องเก็บรวบรวมหรือใช้ข้อมูลส่วนบุคคลและข้อมูลที่เกี่ยวข้องกับภาษี
      (รวมถึงไม่ จำกัด เพียงชื่อจริงข้อมูลบัตรประจำตัวประชาชนและข้อมูลการติดต่อ ฯลฯ ) เมื่อคุณหักภาษี ณ
      ที่จ่ายให้กับเจ้าหน้าที่ภาษีของคุณหรือยื่นภาษีแทนตามที่กฎหมายกำหนดโดยเจ้าหน้าที่ภาษีและคุณควรให้ความร่วมมือกับแพลตฟอร์ม
      UniLive ในการปฏิบัติหน้าที่ด้านภาษีอย่างแข็งขัน
    </p>
    <h3>สิบ, ทรัพย์สินทางปัญญา</h3>
    <p>
      1. สิทธิ์ในทรัพย์สินทางปัญญาของเนื้อหา (รวมถึงแต่ไม่จำกัดเฉพาะซอฟต์แวร์ เทคโนโลยี โปรแกรม เว็บเพจ ข้อความ รูปภาพ
      รูปภาพ เสียง วิดีโอ แผนภูมิ การออกแบบเลย์เอาต์ เอกสารอิเล็กทรอนิกส์ ฯลฯ) ที่ UniLive
      นำเสนอในซอฟต์แวร์นี้และบริการที่เกี่ยวข้อง เป็นของ UniLive
      ลิขสิทธิ์เครื่องหมายการค้าสิทธิบัตรความลับทางการค้าและทรัพย์สินทางปัญญาอื่น ๆ ของซอฟต์แวร์ที่ UniLive
      อาศัยในการให้บริการนี้เป็นของ UniLive ห้ามมิให้บุคคลใดใช้และโอนย้ายโดยไม่ได้รับอนุญาต (รวมถึงแต่ไม่จำกัดเพียง
      สอดแนม คัดลอก เผยแพร่ แสดง มิเรอร์ อัพโหลด
      ดาวน์โหลดเนื้อหาในซอฟต์แวร์นี้และบริการที่เกี่ยวข้องผ่านโปรแกรมหรืออุปกรณ์ใดๆ เช่น หุ่นยนต์ สไปเดอร์)
    </p>
    <p>
      2. คุณรับรองว่าการโพสต์ข้อความ รูปภาพ วิดีโอ เสียง ฯลฯ
      ที่อัปโหลดขณะใช้ซอฟต์แวร์และบริการที่เกี่ยวข้องนั้นเป็นต้นฉบับหรือได้รับอนุญาตโดยชอบด้วยกฎหมาย
      (รวมถึงการอนุญาตต่อ) และสิทธิในทรัพย์สินทางปัญญาในเนื้อหาที่คุณอัปโหลดและเผยแพร่ผ่านซอฟต์แวร์ UniLive
      เป็นของคุณหรือเจ้าของลิขสิทธิ์ดั้งเดิม
    </p>
    <p>
      3.
      เพื่อให้งานของคุณสามารถแบ่งปันและส่งเสริมได้ดียิ่งขึ้นและขยายอิทธิพลของคุณและงานของคุณเว้นแต่คุณจะมีข้อตกลงเป็นลายลักษณ์อักษรที่ตรงกันข้ามกับ
      UniLive คุณรับทราบเข้าใจและตกลงที่จะให้ลิขสิทธิ์และสิทธิในการแข่งขันที่เกี่ยวข้องในเนื้อหาที่คุณโพสต์ /
      อัปโหลดในขณะที่ใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้อง (รวมถึง แต่ไม่ จำกัด
      เฉพาะผลลัพธ์เช่นข้อความรูปภาพวิดีโอเสียงและเนื้อหาและองค์ประกอบอื่น ๆ ที่รวมอยู่ในผลลัพธ์ดังกล่าว) ,
      สิทธิในการรวบรวม, สิทธิในการแสดง, สิทธิในการเผยแพร่เครือข่ายข้อมูล, สิทธิในการออกอากาศ, สิทธิในการผลิตอนุพันธ์
      ฯลฯ), ขอบเขตของการใช้ที่ได้รับอนุญาตดังกล่าวรวมถึงแต่ไม่ จำกัด เฉพาะแพลตฟอร์ม UniLive
      หรือแพลตฟอร์มของบุคคลที่สามอื่น ๆ แอพพลิเคชันผลิตภัณฑ์หรืออุปกรณ์ปลายทางเว็บเพจและผู้ให้บริการอื่น ๆ
      ที่มีอยู่และในอนาคต คุณยอมรับว่า UniLive อาจใช้หรืออนุญาตให้บุคคลที่สามใช้หรือพัฒนาเนื้อหาข้างต้น
      (ทั้งหมดหรือบางส่วน) เพื่อวัตถุประสงค์ในการส่งเสริมการขายหรือการอัพเกรดผลิตภัณฑ์ / คุณลักษณะการวิจัยผลิตภัณฑ์ใหม่
      / คุณลักษณะและสัญญาว่าจะไม่เรียกร้องสิทธิส่วนบุคคลหรือค่าตอบแทนเกี่ยวกับการใช้หรือการพัฒนาของ UniLive
      สำหรับเนื้อหาดังกล่าว เนื้อหาที่คุณอัพโหลดผ่านซอฟต์แวร์ UniLive มีให้สำหรับผู้ใช้ UniLive
      รายอื่นเมื่อสร้างและเผยแพร่เนื้อหาโดยใช้ซอฟต์แวร์ UniLive
      ตามลักษณะของฟังก์ชั่นการบริการบางส่วนและผลงานที่ผู้ใช้สร้างขึ้นใหม่ตามข้อตกลงดังกล่าวสามารถเผยแพร่ได้เฉพาะบนแพลตฟอร์ม
      UniLive หรือแพลตฟอร์มของบุคคลที่สามที่ UniLive ร่วมมือกันและไม่สามารถใช้เพื่อวัตถุประสงค์ทางการค้าใด ๆ
      โดยไม่ได้รับความยินยอมจาก UniLive หากคุณไม่ต้องการให้ UniLive ยังคงใช้เนื้อหาที่ได้รับอนุญาตทั้งหมดหรือบางส่วน
      UniLive สามารถแจ้งให้ UniLive เพิกถอนการอนุญาตดังกล่าวได้ตามที่ประกาศไว้ใน UniLive
    </p>
    <p>
      4. เพื่อปรับปรุงการเปิดรับและประสิทธิภาพในการเผยแพร่ผลงานของคุณ
      คุณตกลงที่จะโพสต์เนื้อหาทั้งหมดที่คุณโพสต์เมื่อใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องอนุญาตให้ UniLive
      โพสต์โดยอัตโนมัติโดยใช้หมายเลขบัญชีของคุณไปยังผู้ให้บริการบริการอื่น ๆ เช่น ไคลเอ็นต์ เว็บไซต์ เว็บเพจ
      และแอปเปิ้ลที่ดำเนินการโดย Beijing UniLive Technology Co. , Ltd. หรือบริษัทในเครือ
      ข้อมูลที่ไม่เป็นความลับเช่นรูปโปรไฟล์และชื่อเล่นของบัญชีของคุณอาจถูกซิงโครไนซ์พร้อมกัน
      หากคุณไม่ต้องการที่จะดำเนินการต่ออนุญาต UniLive ซิงค์โดยอัตโนมัติเนื้อหาที่โพสต์ UniLive
      สามารถเพิกถอนการอนุญาตได้โดยอิสระผ่านการแจ้งเตือนจากหน้าการดำเนินงานที่เกี่ยวข้องกับซอฟต์แวร์ UniLive
      หรือโดยการแจ้งอื่น ๆ
    </p>
    <p>
      5. คุณรับทราบและตกลงที่จะอนุญาตให้ UniLive ในนามของ UniLive
      เองหรือมอบหมายให้บุคคลที่สามดำเนินการปกป้องสิทธิในทรัพย์สินทางปัญญาหรือได้รับอนุญาตตามกฎหมายที่คุณอัปโหลดและโพสต์เมื่อคุณต้องให้ความร่วมมือในการออกเอกสารประกอบเป็นลายลักษณ์อักษรที่สอดคล้องกันตามคำขอของ
      UniLive รูปแบบของการคุ้มครองสิทธิรวมถึง แต่ไม่ จำกัด เพียง:
      การตรวจสอบการละเมิดการส่งจดหมายคุ้มครองสิทธิการฟ้องร้องหรืออนุญาโตตุลาการการไกล่เกลี่ยการประนีประนอม ฯลฯ UniLive
      มีสิทธิ์ในการตัดสินใจเกี่ยวกับการคุ้มครองสิทธิและดำเนินการอย่างเป็นอิสระ
    </p>
    <p>
      6. หากคุณพบเนื้อหาที่ละเมิดสิทธิของคุณในซอฟต์แวร์และเว็บไซต์ที่เกี่ยวข้องโปรดส่งอีเมลทันที [
      unilive.team@gmail.com ] แจ้งให้ UniLive ทราบและให้หลักฐานเบื้องต้นเกี่ยวกับสิทธิของคุณในเวลาเดียวกัน UniLive
      จะจัดการกับข้อร้องเรียนของคุณอย่างทันท่วงทีตามที่กฎหมายกำหนด
    </p>
    <p>
      7. UniLive
      ให้การสนับสนุนด้านเทคนิคในการพัฒนาการดำเนินงานของซอฟต์แวร์และบริการที่เกี่ยวข้องและมีสิทธิอย่างเต็มที่ในข้อมูลและข้อมูลทั้งหมดที่เกิดขึ้นในกระบวนการของการพัฒนาและการดำเนินงานของซอฟต์แวร์และบริการที่เกี่ยวข้อง
      ฯลฯ
    </p>
    <p>
      8. กรุณาอย่าใช้เครื่องหมายการค้า เครื่องหมายบริการ ชื่อทางการค้า ชื่อโดเมน ชื่อเว็บไซต์
      หรือลักษณะแบรนด์ที่สำคัญอื่น ๆ ของ UniLive รวมถึง "UniLive Technologies" "UniLive.com" เป็นต้น
      (ซึ่งต่อไปนี้จะเรียกว่า "โลโก้") คุณไม่สามารถแสดงใช้โลโก้ดังกล่าวของข้อกำหนดฉบับนี้ในลักษณะใด ๆ
      เช่นแยกหรือรวมกันหรือดำเนินการอื่น ๆ
      ในการจัดการกับโลโก้เหล่านี้โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก UniLive
      อันเป็นผลมาจากการที่คุณใช้เครื่องหมายการค้าโลโก้ ฯลฯ ดังกล่าวข้างต้นของ UniLive Corporation
      การละเมิดข้อตกลงนี้ก่อให้เกิดความเสียหายต่อ UniLive หรือบุคคลอื่น คุณจะต้องรับผิดชอบทางกฎหมายทั้งหมด
    </p>
    <h3>สิบเอ็ด, โปรโมชั่น</h3>
    <p>
      1. คุณเข้าใจและยอมรับว่าเราสงวนสิทธิ์ในการวางและ / หรือติดตั้งโฆษณาเชิงพาณิชย์ (รวมถึงลิงก์โฆษณา)
      หรือข้อมูลทางธุรกิจและไม่ใช่เชิงพาณิชย์อื่น ๆ (รวมเรียกว่า "ข้อมูลส่งเสริมการขาย")
      ด้วยตนเองหรือโดยคู่ค้าของเราในการจัดหาซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้อง สิทธินี้รวมถึง แต่ไม่ จำกัด
      เฉพาะข้อมูลที่ UniLive อาจวางและ / หรือติดตั้งการส่งเสริมการขายที่ใดก็ได้บนแพลตฟอร์ม UniLive เช่นหมายเลขบัญชี
      รูปแบบ, ระยะเวลา, ตำแหน่ง, เนื้อหาที่ส่งเสริมการขายและ /
      หรือติดตั้งข้อมูลดังกล่าวจะไม่ถูกแทรกแซงโดยบุคคลที่สามภายใต้สมมติฐานของการปฏิบัติตามกฎหมายและข้อบังคับ
    </p>
    <p>
      2. เกี่ยวกับการโฆษณาเชิงพาณิชย์
    </p>
    <p>
      2.1 หากคุณไม่เต็มใจที่จะรับโฆษณาที่เกี่ยวข้องคุณมีสิทธิ์เลือก "ไม่สนใจ"
      ในข้อความโฆษณานั้นและการผลักดันของโฆษณาประเภทเดียวกันของโฆษณานั้นจะลดลง
    </p>
    <p>
      2.2 เราปฏิบัติตามภาระผูกพันที่เกี่ยวข้องกับการโฆษณาตามที่กฎหมายกำหนด
      แต่คุณเข้าใจและยอมรับว่าคู่ค้าโฆษณาและเนื้อหาโฆษณาไม่ได้อยู่ภายใต้การควบคุมของเรา
      และคุณควรตัดสินอย่างรอบคอบเกี่ยวกับความจริงและความน่าเชื่อถือของเนื้อหาโฆษณา
      การกระทำหรือก่อให้เกิดข้อพิพาทในรูปแบบใด ๆ ที่คุณกระทำกับ / หรือบริการของเรากับพันธมิตรโฆษณาผ่านผลิตภัณฑ์และ /
      หรือบริการของเราเป็นความรับผิดชอบของคุณเองและแก้ไขและเราไม่รับผิดชอบใด ๆ
      เพิ่มเติมนอกเหนือจากความรับผิดชอบตามกฎหมายและข้อบังคับที่เราจะต้องรับผิดชอบ
      แต่จะให้ความช่วยเหลือที่จำเป็นตามกฎหมายตามความจำเป็น
    </p>
    <p>
      2.3 ในกรณีที่ท่านไม่เต็มใจที่จะรับบริการแจ้งเตือน UniLive Push
      ท่านสามารถปิดบริการได้ด้วยตนเองในการจัดการการแจ้งเตือนของระบบโทรศัพท์มือถือ
    </p>
    <h3>สิบสอง. ข้อตกลงพิเศษเกี่ยวกับบริการเดียว</h3>
    <p>
      1. ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องประกอบด้วยข้อมูลหรือการเชื่อมโยงเนื้อหาข้อมูลที่ UniLive
      ได้รับในลักษณะทางกฎหมายต่างๆรวมทั้งบริการเดี่ยวอื่น ๆ ที่ดำเนินการตามกฎหมายโดย UniLive และ บริษัท ในเครือ UniLive
      อาจเพิ่มลดหรือเปลี่ยนแปลงการตั้งค่าและบริการของส่วนพิเศษเหล่านี้เป็นครั้งคราว
      คุณสามารถเปิดและใช้คุณลักษณะบริการเดียวข้างต้นในซอฟต์แวร์ UniLive
      บริการเดียวบางอย่างอาจต้องการให้คุณยอมรับข้อตกลงที่พัฒนาขึ้นเป็นพิเศษเกี่ยวกับบริการหรือกฎอื่น ๆ
      ที่ผูกมัดระหว่างคุณและผู้ให้บริการนั้นในเวลาเดียวกัน UniLive
      จะให้โปรโตคอลกฎระเบียบเหล่านี้ในลักษณะที่สะดุดตาสำหรับการปรึกษาของคุณ
      เมื่อคุณยอมรับข้อตกลงหรือเริ่มใช้บริการข้างต้นจะถือว่าคุณยอมรับเนื้อหาที่ตกลงกันของข้อตกลงและกฎที่เกี่ยวข้องเกี่ยวกับบริการเดียวในเวลาเดียวกัน
    </p>
    <p>
      2. เมื่อคุณใช้ซอฟต์แวร์และ/หรือบริการที่เกี่ยวข้องจากบุคคลที่สามในซอฟต์แวร์ UniLive
      คุณต้องปฏิบัติตามข้อตกลงของบุคคลที่สามกฎที่เกี่ยวข้องนอกเหนือจากการปฏิบัติตามข้อตกลงนี้และกฎของแพลตฟอร์ม UniLive
      ในกรณีที่มีข้อพิพาทความสูญเสียหรือความเสียหายที่เกิดจากซอฟต์แวร์ของบุคคลที่สามและ/หรือบริการที่เกี่ยวข้องได้รับการแก้ไขโดยคุณเองและบุคคลที่สาม
      UniLive จะไม่รับผิดชอบต่อคุณหรือบุคคลที่สามใด ๆ
    </p>
    <h3>สิบสาม การเปลี่ยนแปลง การหยุดชะงัก และการยกเลิกบริการ</h3>
    <p>
      1. ภายใต้ขอบเขตที่กฎหมายและข้อบังคับอนุญาต เราอาจเปลี่ยนแปลงหรือยกเลิกผลิตภัณฑ์และ/หรือบริการ
      (หรือส่วนใดส่วนหนึ่ง) ของเราชั่วคราวหรือถาวร ทั้งนี้ขึ้นอยู่กับการพัฒนาธุรกิจของเรา
      การเปลี่ยนแปลงหรือยกเลิกดังกล่าวจะไม่ต้องรับผิดต่อคุณและบุคคลที่สามใด ๆ
      สำหรับการละเมิดสัญญาเมื่อได้รับแจ้งเป็นลายลักษณ์อักษรในจดหมายภายในสถานี
      การประกาศการอัปเดต/การยกเลิกภายในระยะเวลาที่เหมาะสมล่วงหน้า
    </p>
    <p>
      2. นอกเหนือจากที่กล่าวมาข้างต้น
      เรามีสิทธิที่จะระงับหรือยุติการให้บริการผลิตภัณฑ์และ/หรือบริการแก่คุณเมื่อเกิดเหตุการณ์ดังต่อไปนี้
    </p>
    <p>
      2.1 สิ่งที่คุณได้ร้องขอ
    </p>
    <p>
      2.2
      การมีอยู่ของคุณหรือเราเชื่อว่าคุณมีการละเมิดกฎหมายและข้อบังคับของประเทศหรือนโยบายการกำกับดูแลการละเมิดข้อตกลงนี้หรือการกระทำที่เป็นอันตรายต่อชื่อเสียงและผลประโยชน์ของเรา
    </p>
    <p>
      2.3 เป็นไปตามกฎหมายและข้อบังคับ ข้อกำหนดของนโยบายการกำกับดูแล หรือข้อกำหนดของหน่วยงานที่มีอำนาจ
    </p>
    <p>
      2.4 กรณีจำเป็นเร่งด่วน เช่น การรักษาหมายเลขบัญชีและความปลอดภัยของระบบ
    </p>
    <p>
      2.5 เหตุสุดวิสัย (เหตุสุดวิสัย หมายถึง สถานการณ์ที่ไม่สามารถคาดการณ์ได้ ไม่สามารถหลีกเลี่ยงได้
      และไม่สามารถเอาชนะได้ เนื่องจากลักษณะเฉพาะของอินเทอร์เน็ต
      เหตุสุดวิสัยที่อ้างถึงในข้อตกลงนี้ยังรวมถึงการโจมตีของแฮ็กเกอร์ ผลกระทบที่สำคัญจากการปรับเทคโนโลยีในภาคโทรคมนาคม
      การปิดชั่วคราวเนื่องจากการควบคุมของรัฐบาล การโจมตีของไวรัส และสถานการณ์อื่น ๆ
      ที่ส่งผลกระทบต่อการทำงานของอินเทอร์เน็ต)
    </p>
    <p>
      2.6 กรณีอื่นที่ไม่อาจขัดขืนได้
    </p>
    <p>
      3. ในกรณีที่มีการยุติตามที่อธิบายไว้ข้างต้น คุณและเราทั้งคู่รับรองวิธีการดำเนินการดังต่อไปนี้:
    </p>
    <p>
      3.1 ถ้าท่านมีธุรกรรมที่กำลังดำเนินการอยู่ภายในแพลตฟอร์มของเรา เราจะดำเนินการอย่างสมเหตุสมผลตามความเหมาะสม
    </p>
    <p>
      3.2 ในกรณีที่มีการยกเลิกอันเนื่องมาจากการละเมิดข้อตกลงนี้ของคุณ
      เรามีสิทธิที่จะเรียกร้องให้คุณรับผิดชอบต่อการละเมิดที่เกี่ยวข้องตามความเหมาะสม
    </p>
    <p>
      3.3 เราไม่จำเป็นต้องรับผิดชอบต่อคุณและบุคคลที่สาม เว้นแต่จะระบุไว้เป็นอย่างอื่นในกฎหมายและข้อบังคับ
      หรือที่เราได้ระบุไว้เป็นอย่างอื่น
    </p>
    <h3>สิบสี่, การปฏิเสธความรับผิดชอบ</h3>
    <p>
      1. คุณเข้าใจและยอมรับว่าซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องอาจได้รับผลกระทบหรือถูกรบกวนจากหลายปัจจัยและ
      UniLive ไม่รับประกัน (รวมถึง แต่ไม่ จำกัด เพียง):
    </p>
    <p>
      1.1 ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้องเหมาะสมกับความต้องการใช้งานของผู้ใช้งาน
    </p>
    <p>
      1.2 UniLive ไม่ถูกรบกวน ทันเวลา ปลอดภัย เชื่อถือได้ หรือไม่เกิดข้อผิดพลาด และซอฟต์แวร์บริการหรือวัสดุอื่น ๆ
      ที่ผู้ใช้ได้รับผ่าน UniLive เป็นไปตามความคาดหวังของผู้ใช้
    </p>
    <p>
      1.3 ข้อผิดพลาดใด ๆ ในซอฟต์แวร์ UniLive จะได้รับการแก้ไข
    </p>
    <p>
      2. UniLive จะไม่รับผิดชอบทางกฎหมายใด ๆ ทั้งทางตรง ทางอ้อม โดยบังเอิญ โดยบังเอิญ พิเศษ อนุพันธ์ หรือการลงโทษใด ๆ
      ในกรณีที่ถูกกล่าวหาว่ามีการกู้ยืมเงินหรือทรัพย์สินอื่น ๆ ที่เกี่ยวข้อง เช่น ข้อมูลเครือข่าย รหัสผ่านบัญชี การโฆษณา
      หรือการส่งเสริมการขาย โปรดใช้ความระมัดระวังและตัดสินด้วยตัวคุณเอง
    </p>
    <p>
      3. ท่านเข้าใจและยอมรับว่าอาจพบปัจจัยต่างๆ เช่น เหตุสุดวิสัยระหว่างการใช้ซอฟต์แวร์ UniLive และบริการที่เกี่ยวข้อง
      ในกรณีที่มีเหตุสุดวิสัย UniLive จะพยายามแก้ไขให้ทันเวลาในครั้งแรก
      แต่เนื่องจากเหตุสุดวิสัยทำให้เกิดความสูญเสียแก่ผู้ใช้และผู้ใช้ยอมรับว่า UniLive จะไม่รับผิดชอบ
    </p>
    <p>
      4. UniLive ได้รับสิทธิในการจัดการกับเนื้อหาที่ผิดกฎหมายตามที่ตกลงกันไว้ตามข้อตกลงนี้
      สิทธิดังกล่าวไม่ถือเป็นภาระผูกพันหรือข้อผูกพันของ UniLive และ UniLive
      ไม่สามารถรับประกันได้ว่าจะตรวจพบการกระทำผิดกฎหมายในเวลาที่เหมาะสมหรือดำเนินการประมวลผลที่เกี่ยวข้อง
    </p>
    <p>
      5. คุณเข้าใจและยอมรับว่า UniLive ไม่ได้ให้การรับประกันหรือเงื่อนไขใด ๆ
      ที่เกี่ยวข้องกับบริการนี้โดยชัดแจ้งหรือโดยนัยรวมถึงแต่ไม่ จำกัด
      เฉพาะความสามารถในการขายเชิงพาณิชย์ความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ ฯลฯ
    </p>
    <p>
      6.
      ท่านเข้าใจและตกลงว่าข้อตกลงนี้ร่างขึ้นโดยคู่สัญญาบนพื้นฐานของการปฏิบัติตามกฎหมายและข้อบังคับของประเทศรักษาความสงบเรียบร้อยและจารีตประเพณีการปกป้องสิทธิและผลประโยชน์ที่ชอบด้วยกฎหมายของผู้อื่นและการปรับปรุงประสบการณ์การใช้งานของผู้ใช้
      UniLive สำหรับเรื่องที่เกี่ยวข้องกับการปฏิบัติตามข้อตกลงที่เกี่ยวข้องกับ UniLive
      ที่จะต้องปฏิบัติตามข้อผูกพันในการตรวจสอบการควบคุมหรือแพลตฟอร์มอื่น ๆ UniLive
      จะพยายามอย่างเต็มที่ในขอบเขตของความสามารถในการดำเนินการตัดสินงานที่เกี่ยวข้องตามกฎหมายและระเบียบที่เกี่ยวข้อง
      แต่ไม่รับประกันว่าการตัดสินของ UniLive
      จะสอดคล้องกับการตัดสินของตุลาการหน่วยงานบริหารหากผลที่เกิดขึ้นผู้ใช้มีความเข้าใจและตกลงที่จะดำเนินการด้วยตนเอง
    </p>
    <h3>สิบห้า, การประมวลผลการละเมิด</h3>
    <p>
      1. เพื่อตอบสนองต่อการละเมิดข้อตกลงนี้หรือกฎอื่น ๆ ของแพลตฟอร์ม UniLive ของคุณ UniLive
      มีสิทธิ์ที่จะตัดสินและใช้มาตรการอื่น ๆ ตามความเหมาะสมเช่นการเตือน, แก้ไขกำหนดเวลา, จำกัด
      การทำงานบางส่วนหรือทั้งหมดของบัญชี, ระงับการใช้งานจนกว่าจะสิ้นสุดการให้บริการ, ปิดบัญชี, ห้ามการลงทะเบียนใหม่, ฯลฯ
      เป็นผลมาจากการที่คุณไม่สามารถใช้บัญชีและบริการที่เกี่ยวข้อง, ไม่สามารถที่จะได้รับสินทรัพย์หรือสิทธิประโยชน์อื่น ๆ
      ในบัญชีของคุณตามปกติ (รวมถึง แต่ไม่ จำกัด เพียงความสูญเสียเช่นโปรไฟล์ผู้ใช้,
      สินทรัพย์เสมือนและข้อมูลที่เกี่ยวข้อง, ผลิตภัณฑ์หรือบริการ ฯลฯ ) เป็นความรับผิดชอบของคุณเองและ UniLive
      จะไม่รับผิดชอบทางกฎหมาย UniLive
      มีสิทธิ์ที่จะประกาศการละเมิดและผลการประมวลผลของพวกเขาและมีสิทธิ์ที่จะตัดสินใจว่าจะกลับมาใช้บัญชีที่เกี่ยวข้องหรือไม่ขึ้นอยู่กับสถานการณ์จริง
      UniLive
      จะเก็บบันทึกที่เกี่ยวข้องและมีสิทธิตามกฎหมายในการรายงานไปยังหน่วยงานที่มีอำนาจที่เกี่ยวข้องให้ความร่วมมือกับการสอบสวนของหน่วยงานที่เกี่ยวข้องรายงานต่อหน่วยงานความมั่นคงสาธารณะ
      ฯลฯ สำหรับการละเมิดกฎหมายและข้อบังคับที่สงสัยว่ามีความผิดทางกฎหมาย UniLive มีสิทธิที่จะไม่กู้คืนเนื้อหาที่ถูกลบ
      UniLive
    </p>
    <p>
      2. ในกรณีที่เกิดการร้องเรียนหรือการฟ้องร้องจากบุคคลที่สามอันเนื่องมาจากการละเมิดข้อตกลงหรือกฎของแพลตฟอร์ม UniLive
      อื่น ๆ คุณจะต้องจัดการและรับผิดชอบทางกฎหมายทั้งหมดที่อาจเกิดขึ้นจากการกระทำดังกล่าวด้วยตัวคุณเอง ในกรณีที่ UniLive
      หรือ Affiliate
      ชดใช้ค่าเสียหายแก่บุคคลที่สามหรือถูกลงโทษโดยหน่วยงานของรัฐเนื่องจากการกระทำที่ผิดกฎหมายหรือการผิดสัญญาของคุณคุณจะต้องชดใช้ค่าเสียหายทั้งหมดที่
      UniLive หรือ Affiliate ประสบ
    </p>
    <h3>สิบหก, อื่น ๆ</h3>
    <p>
      1.
      หัวข้อของข้อกำหนดทั้งหมดของข้อตกลงนี้เป็นเพียงเพื่อความสะดวกในการอ่านและไม่ได้มีความหมายในทางปฏิบัติในตัวเองและไม่สามารถใช้เป็นพื้นฐานสำหรับการตีความความหมายของข้อตกลงนี้
    </p>
    <p>
      2.
      บทบัญญัติของข้อตกลงนี้ไม่ว่าจะด้วยเหตุผลใดก็ตามที่บางส่วนเป็นโมฆะหรือไม่สามารถบังคับใช้ได้บทบัญญัติที่เหลือยังคงมีผลบังคับใช้และมีผลผูกพันกับคู่สัญญา
    </p>


  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "한국인",
          label: "한국인",
        },
        {
          value: "日本語",
          label: "日本語",
        }, {
          value: 'Русский',
          label: 'Русский'//俄国
        }, {
          value: 'Tiếng Việt',
          label: 'Tiếng Việt'//越南
        }, {
          value: 'ภาษาไทย',
          label: 'ภาษาไทย'//泰国
        }
      ],
      value: "",
      language: "",
    };
  },
  created() {
    // url获取参数
    this.language = this.$route.query.language;
    if (this.language == "zh_CN") {
      this.$router.push({
        path: "/UserServiceAgreementZh",
      });
    }
    if (this.language == "en_US") {
      this.$router.push({
        path: "/UserServiceAgreementEn",
      });
    }
    if (this.language == 'ko_KR') {
      this.$router.push({
        path: '/UserServiceAgreementKo'
      })
    }
    if (this.language == 'ja_JP') {
      this.$router.push({
        path: '/UserServiceAgreementJa'
      })
    }
    //俄语
    if (this.language == 'ru_RU') {
      this.$router.push({
        path: '/UserServiceAgreementRu'
      })
    }
    //越南语
    if (this.language == 'vi_VN') {
      this.$router.push({
        path: '/UserServiceAgreementVi'
      })
    }
    //泰语
    if (this.language == 'th_TH') {
      this.$router.push({
        path: '/UserServiceAgreementTh'
      })
    }
  },
  methods: {
    clickChange(value) {
      this.value = value;
      if (value == "中文") {
        this.$router.push({
          path: "/UserServiceAgreementZh",
        });
      }
      if (value == "English") {
        this.$router.push({
          path: "/UserServiceAgreementEn",
        });
      }
      if (value == "한국인") {
        this.$router.push({
          path: "/UserServiceAgreementKo",
        });
      }
      if (value == "日本語") {
        this.$router.push({
          path: "/UserServiceAgreementJa",
        });
      }
      if (value == 'Русский') {
        this.$router.push({
          path: '/UserServiceAgreementRu'
        })
      }
      if (value == 'Tiếng Việt') {
        this.$router.push({
          path: '/UserServiceAgreementVi'
        })
      }
      if (value == 'ภาษาไทย') {
        this.$router.push({
          path: '/UserServiceAgreementTh'
        })
      }
    },
  },
};
</script>

<style>
.container {
  padding: 0.1rem 0.3rem;
}

.lang {
  text-align: right;
  margin-bottom: 0.3rem;
}

h1,
h2 {
  color: #333;
  font-size: 0.5rem;
}

h3 {
  margin-top: 20px;
  font-size: 0.4rem;
}

p {
  margin-bottom: 15px;
  font-size: 0.3rem;
}

li {
  font-size: 0.3rem;
}
</style>
